import React from "react"
import { Helmet } from "react-helmet"
import { Layout } from "../components/Layout"
import { Link } from "gatsby"
import { CodeBlock } from "../commonComponents"


export default function SummaryDocs() {
  const no_answer_json = {
      "summary": ""
  }

  const json = {
   "summary": "**Summary of Conversation:**\n\n- **Issue:** The customer reported that the application's \"Flight Log\" feature was not accessible. Clicking on it resulted in an error message, preventing access and updates.\n\n- **Actions Taken:** The technical support agent forwarded the issue to the engineering team for investigation and informed the customer that the problem had been resolved.\n\n- **Resolution:** The issue was fixed, and the customer was notified. The support ticket was subsequently updated and closed, with an invitation to create a new ticket if further assistance was needed."
}

  return (
    <Layout
      keywords="IrisAgent Summary API guide"
      title="Use API to summarize a support conversation"
      description="Documentation on how to use IrisAgent's API to summarize support tickets, emails, or chats"
    >
    <Helmet htmlAttributes={{ lang: 'en' }}>
        <link
          rel="canonical"
          href="https://irisagent.com/summary-api-docs/"
        />
      </Helmet>
      {/* <!-- ======= Breadcrumbs ======= --> */}
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h2>Summary API Guide</h2>
            <ol>
              <li>
                <Link to="/irisagent-docs/">Home</Link>
              </li>
              <li>Summary API</li>
            </ol>
          </div>
        </div>
      </section>
      {/* <!-- End Breadcrumbs --> */}
      <section class="inner-page">
          <div class="container">
            <div data-custom-class="body"
            style={{ marginBottom: "5%", marginTop: "5%", margin: "10%" }}>
                    <h1>Summary API Documentation</h1>
                    <br/>
                    <h2>
                    Introduction
                    </h2>
                    <br/>

                    IrisAgent's AI can be used an API to summarize support conversations to know the core of the customer issue and the resolution steps taken by the support team.
                    <br/>
                    <br/>
                    <h2>
                    API Guide
                    </h2>
                    <br/>
                    Below is a <span style={{"font-family": "Consolas"}}>curl</span> command to call our Summary API:
                    <CodeBlock language="javascript" value={`
curl -X POST --location "https://api1.irisagent.com/v1/summary" \\
    -H "Content-Type: application/json" \\
    -H "Authorization: Bearer Replace_me_business_id" \\
    -H "X-Language-Iso-Code: en" \\
    -d '{
          "conversationId": "12345",
          "subject": "Application will not access Flight Log",
          "description": "When clicking on Flight Log the application keeps producing an ERROR message and will not allow access or Flight Log updating. ",
          "comments":[
              {
                  "comment": "Please help to fix this ASAP.",
                  "commenter": "Customer"
              },
              {
                  "comment": "Thank you for the information. I have forwarded this issue with all the details to our engineering team for further investigation. I will let you know as soon as any new information becomes available. John Doe | Technical Support Specialist | Acme Systems International, Inc.",
                  "commenter": "Agent"
              },
                {
                    "comment": "Billy - Please let the customer know that this issue has been fixed.",
                    "commenter": "Agent"
                },
                {
                    "comment": "I have updated and closed this issue as resolved. While I'm confident we've addressed your issues, if you have any other questions, comments or concerns feel free to create a new ticket. I'm here to help.Please do NOT reply to this email unless you would like us to reopen it. Thank you \n John Doe | Technical Support Specialist O: _ • TF: _\nAcme Systems International, Inc.",
                    "commenter": "Agent"
                }
          ]
        }'
          `}>
</CodeBlock>
<br/>
The API inputs are as follows,
<br/><br/>
<table style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
    <tr>
        <th>Field</th>
        <th>Description</th>
    </tr>
    <tr>
        <td><span style={{"font-family": "Consolas"}}>conversationId</span></td>
        <td>[Required] Unique ticket or chat id</td>
    </tr>
    <tr>
        <td><span style={{"font-family": "Consolas"}}>subject</span></td>
        <td>[Required] Initial query by the customer. This could be the subject of the email or the first query by the customer in a chat conversation.</td>
    </tr>
    <tr>
        <td><span style={{"font-family": "Consolas"}}>description</span></td>
        <td>[Optional] Details on the initial query by the customer. This could be the description of the initial email by the customer. Leave empty for a chat conversation.</td>
    </tr>
    <tr>
        <td><span style={{"font-family": "Consolas"}}>comments</span></td>
        <td>[Optional] Array of messages between the customer and the agent. Each message should have the following fields:
        <ul>
        <li><span style={{"font-family": "Consolas"}}>comment</span>: [Required] Message content</li>
        <li><span style={{"font-family": "Consolas"}}>commenter</span>: [Required] Author of the message: Either "Customer" or "Agent"</li>
        </ul>
        </td>
    </tr>
    <tr>
        <td><span style={{"font-family": "Consolas"}}>Replace_me_business_id</span></td>
        <td>This is the ID provided by the IrisAgent team</td>
    </tr>
    <tr>
        <td><span style={{"font-family": "Consolas"}}>X-Language-Iso-Code</span></td>
        <td>[Optional] This optional header allows you to specify the desired language for IrisGPT's responses. If not provided, automatic language detection will be used</td>
    </tr>
</table>
<br/><br/>
A sample output of the API:
<br/><br/>
<CodeBlock language="json" value={JSON.stringify(json, null, 2)}>
</CodeBlock>

<br/><br/>
A sample output of the API when no summary was generated:
<br/><br/>
<CodeBlock language="json" value={JSON.stringify(no_answer_json, null, 2)}>
</CodeBlock>

                    </div>
                    </div>
                    </section>

</Layout>
  )
}